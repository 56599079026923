<template>
    <div class="Record">
        <div class="title flex">
            <div>
                <label class="record-title">客户跟踪概况</label>
                <a-input-search
                        style="display: none"
                        class="record-search"
                        placeholder="搜索姓名"
                        v-model="Name"
                        @search="onSearch"
                />
                <!--<a-button style="" @click="totay">totay</a-button>-->
            </div>
            <div>
                <a-button @click="totay" style="margin-right: 50px">totay</a-button>
                <span class="record-tips tips-warning">已过期</span>
                <span class="record-tips tips-success">已跟进</span>
                <span class="record-tips tips-error">未跟进</span>
            </div>
        </div>
        <a-calendar @panelChange="onPanelChange" :value="moment(dateValue)">
            <ul class="events" slot="dateCellRender" slot-scope="value">
                <li v-for="item in getListData(value)" :key="item.content">
          <span
                  v-if="item.Status == 1"
                  class="success"
                  @click="StudentInfo(item.Pid, item.StudentName)"
          >{{ item.StudentName
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
          >
                    <span
                            v-else-if="item.Status == 3"
                            class="warning"
                            @click="StudentInfo(item.Pid, item.StudentName)"
                    >{{ item.StudentName
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
                    >
                    <span
                            v-else-if="item.Status == 4"
                            class="more"
                            @click="moreData(item.uid, item.Time)"
                    >+&nbsp;<span class="more-tipe">{{ item.Count }}</span
                    >&nbsp;more</span
                    >
                    <span
                            v-else
                            class="error"
                            @click="StudentInfo(item.Pid, item.StudentName)"
                    >{{ (item.StudentName, item.StudentNames)
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
                    >
                </li>
            </ul>
            <template slot="monthCellRender" slot-scope="value">
                <div
                        v-for="item in getMonthData(value)"
                        :key="item.content"
                        class="notes-month"
                >
          <span
                  v-if="item.Status == 1"
                  class="success"
                  @click="StudentInfo(item.Pid, item.StudentName)"
          >{{ item.StudentName
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
          >
                    <span
                            v-else-if="item.Status == 3"
                            class="warning"
                            @click="StudentInfo(item.Pid, item.StudentName)"
                    >{{ item.StudentName
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
                    >
                    <span
                            v-else-if="item.Status == 4"
                            class="more"
                            @click="moreData(item.uid, item.Time)"
                    >+&nbsp;<span class="more-tipe">{{ item.Count }}</span
                    >&nbsp;more</span
                    >
                    <span
                            v-else
                            class="error"
                            @click="StudentInfo(item.Pid, item.StudentName)"
                    >{{ item.StudentName
            }}{{ item.Trackingtime ? '(' + item.Trackingtime + ')' : '' }}</span
                    >
                </div>
            </template>
        </a-calendar>
        <a-drawer
                :title="nowTime + '客户跟踪概况'"
                width="1000"
                :closable="false"
                @close="onClose"
                :visible="visible"
        >
            <div style="margin-bottom: 25px">
                <a-button-group size="large">
                    <a-button
                            :class="TableStatus ? '' : 'ant-btn-primary'"
                            @click="SearchData('')"
                    >全部
                        <span :class="TableStatus ? 'ant-bth-color' : ''"
                        >({{ Count }})</span
                        >
                    </a-button>
                    <a-button
                            @click="SearchData(1)"
                            :class="TableStatus == 1 ? 'ant-btn-primary' : ''"
                    >
                        已跟进
                        <span :class="TableStatus != 1 ? 'ant-bth-color' : ''"
                        >({{ successCount }})</span
                        >
                    </a-button>
                    <a-button
                            @click="SearchData(3)"
                            :class="TableStatus == 3 ? 'ant-btn-primary' : ''"
                    >
                        未跟进
                        <span :class="TableStatus != 3 ? 'ant-bth-color' : ''"
                        >({{ errorCount }})</span
                        >
                    </a-button>
                </a-button-group>
            </div>
            <a-table
                    v-if="Popupdata"
                    :columns="columns"
                    :dataSource="Popupdata"
                    bordered
            >
                <template slot="Status" slot-scope="Status">
                    <span v-if="Status == 1" class="hook"> √</span>
                </template>
                <template slot="StudentName" slot-scope="StudentName">
          <span
                  class="StudentName"
                  @click="StudentInfo(StudentName[1], StudentName[0])"
          >
            {{ StudentName[0] }}</span
          >
                </template>
            </a-table>
            <div
                    :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px'
        }"
            >
                <a-button style="marginRight: 8px" @click="onClose" type="primary">
                    确认
                </a-button>
                <a-button @click="onClose">
                    取消
                </a-button>
            </div>
        </a-drawer>

        <div
                v-show="spinning"
                style="position:fixed;left:50%;top: 50%;transform: translate(-50%,-50%)"
        >
            <a-spin>
                <a-icon
                        slot="indicator"
                        type="loading"
                        :spinning="spinning"
                        style="font-size: 44px;color:red;"
                        spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'Record',
        data() {
            return {
                dateValue: '',
                userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
                TheTime: '',
                Name: '',
                visible: false,
                nowTime: '',
                list: '',
                successCount: '',
                errorCount: '',
                Count: '',
                columns: [
                    {
                        title: '是否跟进',
                        key: 'Status',
                        dataIndex: 'Status',
                        width: 92,
                        scopedSlots: {customRender: 'Status'}
                    },
                    {
                        title: '客户姓名',
                        key: 'StudentName',
                        dataIndex: 'StudentName',
                        width: 92,
                        scopedSlots: {customRender: 'StudentName'}
                    },
                    {
                        title: '客户状态',
                        key: 'StudentStatus',
                        dataIndex: 'StudentStatus',
                        width: 92
                    },
                    {
                        title: '跟踪状态',
                        key: 'TrecordStatusName',
                        dataIndex: 'TrecordStatusName',
                        width: 92
                    },
                    {title: '跟踪记录', key: 'RecordTit', dataIndex: 'RecordTit'},
                    {
                        title: '跟踪时间',
                        key: 'RecordTime',
                        dataIndex: 'RecordTime',
                        width: 165
                    }
                ],
                Popupdata: '',
                tableUserid: '',
                tableTime: '',
                TableStatus: '',
                MonthList: '',
                spinning: false
            }
        },
        created() {
            let date = new Date()
            let time =
                date.getFullYear() +
                '-' +
                (date.getMonth() + 1 < 10
                    ? '0' + (date.getMonth() + 1)
                    : date.getMonth() + 1)
            this.dateValue = time
            if (this.userinfo.uid == '7520669f3b20008c6a6df71e9cebc2f9') {
                this.userinfo.uid = '4a7297b6394258ffe17bffb92d2fb72c'
            }
            this.loadData()
            this.loadMonth()
        },
        methods: {
            moment,
            loadData() {
                let self = this
                this.$axios.post(
                    1048,
                    {
                        uid: self.userinfo.uid,
                        TheTime: self.TheTime,
                        Name: self.Name
                    },
                    res => {
                        console.log(res)
                        if (res.data.code == 1) {
                            self.list = res.data.message
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },

            getListData(value) {
                let date = new Date(value._d)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1) +
                    '-' +
                    (date.getDate() < 10 ? '0' + date.getDate() : date.getDate())
                return this.list[time]
            },
            onPanelChange(value) {
                let date = new Date(value._d)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                this.dateValue = time
                this.TheTime = time
                this.loadData()
            },
            totay() {
                let date = new Date()
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                this.dateValue = time
                this.TheTime = time
                this.loadData()
            },
            //年月切换
            getMonthData(value) {
                let date = new Date(value)
                let time =
                    date.getFullYear() +
                    '-' +
                    (date.getMonth() + 1 < 10
                        ? '0' + (date.getMonth() + 1)
                        : date.getMonth() + 1)
                return this.MonthList[time]
                // if (value.month() === 8) {
                //     return 1394;
                // }
            },
            loadMonth() {
                let self = this
                this.$axios.post(
                    1050,
                    {
                        uid: self.userinfo.uid,
                        TheTime: self.TheTime,
                        Name: self.Name
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.MonthList = res.data.message
                        } else {
                            this.$message.error(res.data.message)
                        }
                    }
                )
            },
            onSearch() {
                this.loadData()
            },
            moreData(userid, time) {
                ;(this.tableUserid = userid), (this.nowTime = time)
                this.tableFn(userid, time, '')
            },
            SearchData(num) {
                this.TableStatus = num
                this.tableFn(this.tableUserid, this.nowTime, num)
            },
            tableFn(userid, time, status) {
                this.spinning = true
                let self = this
                this.$axios.get(
                    1049,
                    {
                        uid: userid,
                        TheTime: time,
                        Status: status
                    },
                    res => {
                        if (res.data.code == 1) {
                            self.errorCount = res.data.message.errorCount
                            self.successCount = res.data.message.successCount
                            self.Count = res.data.message.Count
                            self.Popupdata = res.data.message.data
                            self.spinning = false
                            self.visible = true
                        }
                    }
                )
            },
            StudentInfo(studentid, studentName) {
                let data = {
                    type: 'student',
                    name: studentName,
                    id: studentid
                }
                window.parent.postMessage(data, '*')
                //window.open("http://ims.qitianzhen.cn/index.php?m=Kms&c=Student&a=edit&UserId="+studentid,'target','');
            },
            onClose() {
                this.TableStatus = ''
                ;(this.tableUserid = ''), (this.nowTime = '')
                this.visible = false
            }
        }
    }
</script>

<style lang="less" scoped>
    * {
        font-family: Microsoft YaHei;
    }

    .Record {
        padding: 20px;
        background: #ffffff;
    }

    .events {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .events .ant-badge-status {
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        font-size: 12px;
    }

    .notes-month {
        text-align: center;
        font-size: 28px;
    }

    .record-title {
        font-size: 18px;
        color: rgba(102, 102, 102, 1);
    }

    .hook {
        color: #2994ffff;
    }

    .StudentName {
        color: #2994ffff;
    }

    .record-search {
        margin-right: 30px;
        margin-left: 30px;
        width: 200px;
        border-radius: 35px;
    }

    .record-tips {
        margin-right: 36px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        position: relative;
    }

    .tips-warning::after {
        content: '';
        width: 6px;
        height: 6px;
        background: rgba(255, 156, 8, 1);
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-error::after {
        content: '';
        width: 6px;
        height: 6px;
        background: rgba(224, 225, 226, 1);
        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    .tips-success::after {
        content: '';
        width: 6px;
        height: 6px;
        background: rgba(117, 194, 85, 1);

        opacity: 1;
        border-radius: 4px;
        position: absolute;
        top: 50%;
        left: -30%;
        transform: translateY(-50%);
    }

    /*.notes-month section {*/
    /*font-size: 28px;*/
    /*}*/
    /*.ant-fullcalendar-date{*/
    /*height: 190px !important;*/
    /*}*/
    /*.ant-fullcalendar-fullscreen .ant-fullcalendar-content{*/
    /*height: auto!important;*/
    /*}*/
    .ant-bth-color {
        color: rgb(41, 148, 255);
    }

    .success {
        //已跟进
        background: rgba(117, 194, 85, 1);
        border-radius: 4px;
        color: #ffffff;
        font-size: 12px;
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .warning {
        //已过期
        background: rgba(255, 156, 8, 1);
        border-radius: 4px;
        color: #ffffff;
        font-size: 12px;
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .error {
        //未跟进
        background: rgba(224, 225, 226, 1);
        border-radius: 4px;
        font-size: 12px;
        color: rgba(102, 102, 102, 1);
        display: block;
        border-bottom: 1px solid #ffffff;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;
    }

    .more {
        font-size: 12px;
        color: rgba(36, 36, 36, 1);
        text-align: left;
        display: block;
        width: 100%;
        position: absolute;
        bottom: 10px;
        .more-tipe {
            color: #ff0000ff;
        }
    }
</style>
